.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px !important;

  &::before,
  &::after {
    display: none;
  }

  .ant-collapse-extra {
    display: flex;
  }

  .@{class-prefix}-collapse-arrow {
    transition: 0.4s;
  }
}

.ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-extra {
  .@{class-prefix}-collapse-arrow {
    transform: rotate(90deg);
  }
}

@media @sm {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 24px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 24px !important;
  }
}

  .ant-collapse-content>.ant-collapse-content-box {
    padding: 16px 24px !important;
  }
// }