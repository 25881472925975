.labels {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  color: #444;
}

.flex_v_center {
  display: flex !important;
  align-items: center;
}

.flex_full_page {
  height: 100vh;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_center_col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex_col {
  flex-direction: column;
}

.variant_box {
  display: flex;
  margin-top: 10px;
}

.footer_separator {
  width: 1px;
  height: 20px;
  background-color: #a5a5a5;
  margin-left: 4px;
  margin-top: 15px;
}

.variant_box_child {
  border: #666 solid 1px;
  padding: 10px;
}

.selected_variant_box {
  background-color: #f5f5f5;
  margin-right: 10px;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
}

.full_screen_flex {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.flex {
  display: flex;
}

.error_field {
  color: red;
}

.hide_on_mobile {
  display: flex;
}

.show_on_mobile {
  display: none;
}

@media (max-width: 800px) {
  .hide_on_mobile {
    display: none;
  }
  .show_on_mobile {
    display: block;
  }
  .profile_heading {
    font-size: 20px;
  }
}

/* 
  On Boarding
  ==============================
  */

.type_selector {
  display: flex;
}

.type_item {
  cursor: pointer;
  height: 130px;
  width: 140px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

/* 
  Checkbox
  */

.checkbox_wrapper {
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  justify-content: flex-end;
}

.checkbox_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
  Promotion card
  */

.promotion_card_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.promotion_card {
  background-color: #eee;
  min-height: 200px;
  width: 320px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin: 0 15px 15px 0;
  position: relative;
}

.promotion_desc {
  font-size: 12px;
  color: #999;
  margin: 2px 0;
}

/* 
  image uploader
  ============================
  */

.image_upload_container {
  display: flex;
  flex-wrap: wrap;
}

.image_box {
  height: 105px;
  width: 105px;
  border: #d5d5d5 1px solid;
  position: relative;
  margin-right: 20px;
}

.image_box_big {
  height: 305px;
  width: 255px;
  border: #d5d5d5 1px solid;
  position: relative;
  margin-right: 20px;
}

.upload_box {
  height: 105px;
  width: 105px;
  background-color: #f5f5f5;
  border: #d5d5d5 1px dashed;
  position: relative;
  cursor: pointer;
}

.upload_box_big {
  height: 305px;
  width: 255px;
  background-color: #f5f5f5;
  border: #d5d5d5 1px dashed;
  position: relative;
  cursor: pointer;
}

.session_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.session_modal > p {
  margin-top: 20px;
  font-size: 20px;
}

.image_delete_icon {
  color: #ca0b00;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #d5d5d5;
  padding: 2px;
  border-radius: 60px;
  cursor: pointer;
}

.document_info {
  width: 100%;
  margin: 20px 0;
}

.uploaded_doc_rows {
  display: flex;
  padding: 10px 0;
  border: #d5d5d5 solid 1px;
}

.uploaded_doc_rows:first-child {
  border-bottom: transparent;
}

.uploaded_doc_child {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* 
  Selected Variants
  =========================
  */

.variant_card {
  border: 1px solid #333;
  padding: 10px;
  margin-bottom: 15px;
}
